import logo from "./logo.svg"

const clientData = {
    client_entity: 57,
    attorney_firm: 'diazlawfirm.com',
    attorney_name: 'Christopher Williams',
    attorney_number: '601-607-3456',
    attorney_email: 'Chris@diazlawfirm.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_diaz_law_firm+(720p).mp4',
    mainColor: '#0c1d3d',
    secondaryColor: '#0c1d3d',
    siteLink: 'http://www.diazlawfirm.com/',
    logo
}
//url is https://diazlawfirm.patriotdataprocessing.com/
export default clientData